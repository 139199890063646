import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';

// Use consistent styling
import 'sanitize.css/sanitize.css';

// Import root app
import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';

import reportWebVitals from 'reportWebVitals';

import { SENTRY_DSN, SENTRY_ENV, USE_MOCK, NODE_ENV } from 'utils/constants';

// sentry
import * as Sentry from '@sentry/react';

// Initialize languages
import './locales/i18n';
import { ErrorContainer } from 'app/containers/Root/ErrorContainer';
import { errorsFilter } from 'utils/errors';
import { checkVersionAndClearCache } from 'utils/cookies';

export const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

if (NODE_ENV === 'production') {
  errorsFilter();
}

if (!USE_MOCK && Boolean(SENTRY_DSN)) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    integrations: [Sentry.browserTracingIntegration()],
    ignoreErrors: ['Non-Error promise rejection captured'],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOMClient.createRoot(MOUNT_NODE!).render(
  <Sentry.ErrorBoundary fallback={() => <ErrorContainer />}>
    <Provider store={store}>
      <HelmetProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </HelmetProvider>
    </Provider>
  </Sentry.ErrorBoundary>,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

checkVersionAndClearCache();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
