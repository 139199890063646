import { PaletteMode } from '@mui/material';
import { StyleConstants } from '../../StyleConstants';
import { LayoutStyles } from '../types';

export const getLayoutStyles = (_mode: PaletteMode): LayoutStyles => {
  return {
    loadingPage: {
      circle: '#ddd',
      borderColor: '#ddd',
      background: 'rgba(255,255,255,1)',
      backgroundTransparent: 'rgba(255,255,255,.5)',
    },
    header: {
      height: StyleConstants.HEADER_HEIGHT,
      background: '#fff',
      contrastText: '#2C2C2C',
      borderColor: '#E9E9E9',
    },
    summary: {
      section: {
        background: '#FAFAFA',
        borderColor: '#E4E4E4',
      },
      message: {
        color: {
          primary: '#CEC1FC',
          secondary: '#FFD6A7',
          danger: '#E58B86',
        },
      },
    },
    questionnaire: {
      headerHeight: '4rem',
      question: {
        suggested: '#F5F7FC',
        answerBorderColor: '#CBD2E0',
        answerColor: '#4E4E4E',
      },
    },
    alert: {
      errorBorder: '3px solid #BF0330',
    },
    aside: {
      color: '#505050',
    },
    pad: {
      background: '#FAFAFA',
    },
    card: {
      background: '#EBF1FF',
    },
    table: {
      tableBorder: {
        default: '',
        lightBody: 'border: 1px solid #d3d3d3; border-radius: 6px;',
      },
      headerBorder: {
        default: 'border-bottom: 1px solid #d3d3d3',
        lightBody: 'border-bottom: 1px solid #d3d3d3',
      },
      headerBackground: {
        default: 'background: #fff;',
        lightBody: 'background: #fff;',
      },
      headerPadding: {
        default: 'padding: .5rem 1rem',
        small: 'padding: .2rem .5rem',
        medium: 'padding: 1rem 1.2rem',
      },
      rowPadding: {
        default: 'padding: .5rem 1rem',
        small: 'padding: .2rem .5rem',
        medium: 'padding: 1rem 1.2rem',
      },
      rowBorder: {
        default: '',
        lightBody: 'border-top: 1px solid #d3d3d3',
      },
      rowBackground: {
        default: 'background: #fff;',
        lightBody: 'background: #fafafa;',
      },
      rowHover: {
        default: 'background: #fff;',
        lightBody: 'background: #f5f2ff;',
      },
      highlightColor: {
        default: '',
        lightBody: 'color: #f00c0c;',
      },
    },
    newProcessWizard: {
      asideBackground: `
        background: rgb(8, 0, 60);
        background: linear-gradient(
          153deg,
          rgba(8, 0, 60, 1) 0%,
          rgba(17, 2, 83, 1) 83%
        );
      `,
      asideItemColor: {
        default: '#fff',
        active: '#100146',
        disabled: '#656565',
      },
      asideItemBackground: {
        default: 'transparent',
        active: '#fff',
        disabled: 'transparent',
      },
    },
    navigationWidget: {
      background: '#2D2E48',
      color: '#fff',
      divider: '#47496F',
    },
    tooltip: {
      backgroundColor: '#2D2E48',
    },
    walkthroughTooltip: {
      zIndex: '1001',
    },
    pdfViewer: {
      backgroundColor: '#d3d3d3',
    },

    filters: {
      filterPanel: {
        height: '5rem',
      },
      filterItem: {
        backgroundColor: '#F5F2FF',
        height: '2rem',
      },
      categoryFilteredBadge: {
        backgroundColor: '#CEC1FC',
      },
    },
  };
};
